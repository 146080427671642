<template>
  <div class="download-button">
    <b-button @click="downloadPackShots" variant="primary">{{$T('download')}}</b-button>
  </div>
</template>

<script>

export default {
  name: 'PackShotDownloadButton',
  props: {
    name: {
      type: String,
      required: true
    },
    urlList: {
      type: Array,
      required: true
    }
  },
  methods: {
    downloadPackShots () {
      this.$store.dispatch('packshotlistcart/zipFile', { fileName: this.name, urls: this.urlList }).then(response => {
        var filename = ''
        var disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(response.data)
        a.download = filename
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    }
  }
}
</script>

<style lang="scss">
.download-button{
  position:absolute;
  bottom:0;
  right:0;
}

</style>
